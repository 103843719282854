// Body

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
$gray-600: #6a737b !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// Colors

$body-bg: #f8fafc;

// $blue: #3490dc;
// $blue: #343982;
$blue: #00779C;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
// $red: #e3342f;
$red: #e73c48; // from vicra logo
$orange: #deb48b;
// $orange: #E8BD17;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
// $cyan: #6cb2eb;
$cyan: #17B7E8;


// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

$primary: #d23742; //#e73c48;
$secondary: #7d96a8; //#91ADC2;
$success: #7AFF9B;
$info: #61C8FF !default;
$warning: #E89517 !default;
$danger: #E73C48 !default;
$light: $gray-100 !default;
$dark: #10141F;